<template>
    <div id="body-content">
        <div v-for="(project, index) in projects" :key="index">
            <div v-if="ProjectId == project.projectId">
                <!-- Section: Mini Hero -->
                <div class="section-mini-hero" :style="{'background-image': 'url(' + project.img2 + ')'}">
                </div>
                <!-- /Section: Mini Hero -->
                <h1 class="project-single-title title-border title-project-mobile">{{project.cateProject}}</h1>
                <!-- Section: Single project -->
                <div class="section-project-single">
                    <div class="container">
                        <div class="row project-single-content">
                            <div class="col-sm-4">
                                <div class="project-single-pic project-single-pic3" :style="{'background-image': 'url(' + project.img + ')'}">
                                </div>									
                            </div>			
                            <div class="col-sm-8"> 					
                                <div class="project-single-text">
                                    <h1 class="project-single-title title-border hide-title-mobile">{{project.cateProject}}</h1>
                                    <p>{{project.description1}}</p>
                                    <p>{{project.description2}}</p>
                                    <p>{{project.description3}}</p>
                                    <p class="project-price">{{ $t('singleProject.price') }} {{project.price}}</p> 
                                </div>
                                <div class="section-project-infos">
                                    <div class="row">
                                        <div class="col-sm-4 project-infos-title-wrapper">
                                            <div class="project-infos-col">
                                                <h2 class="title1">{{ $t('singleProject.moreDetail') }}</h2>
                                            </div>					
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="project-infos-col">
                                                <h3 class="title4"><span class="fa fa-bed"></span>&nbsp;&nbsp;{{project.bedRoom}}</h3>
                                                <h3 class="title4"><span class="fa fa-bath"></span>&nbsp;&nbsp;{{project.toilet}}</h3>
                                                <h3 class="title4"><span class="fa fa-tv"></span>&nbsp;&nbsp;{{project.livingRoom}}</h3>							
                                            </div>					
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="contact-infos-col">
                                                <h3 class="title4">{{ $t('singleProject.location') }}</h3>
                                                <p class="sub-title4">{{project.location}}</p>
                                                <h3 class="title4">{{ $t('singleProject.area') }}</h3>
                                                <p class="sub-title4">{{project.area}}</p>
                                            </div>									
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Section: Projects -->
                        <div class="section-projects-slide">
                            <p class="title2 title-border title-section">{{ $t('singleProject.gallery') }}</p>
                            <div class="container-fuild">
                                <div class="row">
                                    <div class="column">
                                        <img v-lazy="{src: project.img2}" alt="" onclick="openModal();currentSlide(1)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img3}" alt="" onclick="openModal();currentSlide(2)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img4}" alt="" onclick="openModal();currentSlide(3)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img5}" alt="" onclick="openModal();currentSlide(4)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img6}" alt="" onclick="openModal();currentSlide(5)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img}" alt="" onclick="openModal();currentSlide(6)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img8}" alt="" onclick="openModal();currentSlide(7)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img9}" alt="" onclick="openModal();currentSlide(8)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img10}" alt="" onclick="openModal();currentSlide(9)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img11}" alt="" onclick="openModal();currentSlide(10)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img12}" alt="" onclick="openModal();currentSlide(11)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img13}" alt="" onclick="openModal();currentSlide(12)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img14}" alt="" onclick="openModal();currentSlide(13)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img15}" alt="" onclick="openModal();currentSlide(14)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img16}" alt="" onclick="openModal();currentSlide(15)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img17}" alt="" onclick="openModal();currentSlide(16)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img18}" alt="" onclick="openModal();currentSlide(17)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img19}" alt="" onclick="openModal();currentSlide(18)" class="hover-shadow cursor">
                                    </div>
                                    <div class="column">
                                        <img v-lazy="{src: project.img20}" alt="" onclick="openModal();currentSlide(19)" class="hover-shadow cursor">
                                    </div>
                                </div>

                                <div id="myModal" class="modal">
                                    <span class="close cursor" onclick="closeModal()">&times;</span>
                                    <div class="modal-content">
                                        <div class="mySlides">
                                            <div class="numbertext">1 / 20</div>
                                            <img v-lazy="{src: project.img2}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">2 / 20</div>
                                            <img v-lazy="{src: project.img3}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">3 / 20</div>
                                            <img v-lazy="{src: project.img4}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">4 / 20</div>
                                            <img v-lazy="{src: project.img5}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">5 / 20</div>
                                            <img v-lazy="{src: project.img6}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">6 / 20</div>
                                            <img v-lazy="{src: project.img}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">7 / 20</div>
                                            <img v-lazy="{src: project.img7}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">8 / 20</div>
                                            <img v-lazy="{src: project.img8}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">9 / 20</div>
                                            <img v-lazy="{src: project.img9}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">10 / 20</div>
                                            <img v-lazy="{src: project.img10}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">11 / 20</div>
                                            <img v-lazy="{src: project.img11}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">12 / 20</div>
                                            <img v-lazy="{src: project.img12}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">13 / 20</div>
                                            <img v-lazy="{src: project.img13}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">14 / 20</div>
                                            <img v-lazy="{src: project.img14}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">15 / 20</div>
                                            <img v-lazy="{src: project.img15}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">16 / 20</div>
                                            <img v-lazy="{src: project.img16}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">17 / 20</div>
                                            <img v-lazy="{src: project.img17}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">18 / 20</div>
                                            <img v-lazy="{src: project.img18}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">19 / 20</div>
                                            <img v-lazy="{src: project.img19}" alt="" style="width:100%">
                                        </div>
                                        <div class="mySlides">
                                            <div class="numbertext">20/ 20</div>
                                            <img v-lazy="{src: project.img20}" alt="" style="width:100%">
                                        </div>
                                        <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
                                        <a class="next" onclick="plusSlides(1)">&#10095;</a>
                                    </div>
                                </div>
                            </div>
                        </div>	
                        <!-- /Section: Projects -->
                        <div class="project-single-footer">
                            <!-- <div class="row">
                                <div class="col-sm-6">
                                    <a href="single-project.html" class="btn2 btn-largeW click-fade btn-padding"><span class="fa fa-angle-double-left"></span>&nbsp;&nbsp;Previous Project</a>
                                    <a href="single-project.html" class="btn2 btn-largeW click-fade">Next Project&nbsp;&nbsp;<span class="fa fa-angle-double-right"></span></a>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <a href="contact.html" class="btn2 btn-largeW click-fade">Get in Touch &nbsp;&nbsp;<span class="fa fa-paper-plane-o"></span></a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- /Section: Single project -->
            </div>
        </div>	
    </div>
</template>
<script>
export default {
    name: 'single-project',
    data() {
        return {
            ProjectId: this.$route.params.PjId,
            title: "single-project",
            projects: [
                {
                    projectId: 1,
                    img: require('../../src/assets/singleHouse-white-soldout.webp'),
                    img2: require('../../src/assets/singleHouse-white/singleHouse-white-1.webp'),
                    img3: require('../../src/assets/singleHouse-white/singleHouse-white-2.webp'),
                    img4: require('../../src/assets/singleHouse-white/singleHouse-white-3.webp'),
                    img5: require('../../src/assets/singleHouse-white/singleHouse-white-4.webp'),
                    img6: require('../../src/assets/singleHouse-white/singleHouse-white-5.webp'),
                    img7: require('../../src/assets/singleHouse-white/singleHouse-white-6.webp'),
                    img8: require('../../src/assets/singleHouse-white/S__88711179_1_11zon.webp'),
                    img9: require('../../src/assets/singleHouse-white/S__88711181_2_11zon.webp'),
                    img10: require('../../src/assets/singleHouse-white/S__88711182_3_11zon.webp'),
                    img11: require('../../src/assets/singleHouse-white/S__88711183_4_11zon.webp'),
                    img12: require('../../src/assets/singleHouse-white/S__88711184_5_11zon.webp'),
                    img13: require('../../src/assets/singleHouse-white/S__88711185_6_11zon.webp'),
                    img14: require('../../src/assets/singleHouse-white/S__88711186_7_11zon.webp'),
                    img15: require('../../src/assets/singleHouse-white/S__88711187_8_11zon.webp'),
                    img16: require('../../src/assets/singleHouse-white/S__88711188_9_11zon.webp'),
                    img17: require('../../src/assets/singleHouse-white/S__88711189_10_11zon.webp'),
                    img18: require('../../src/assets/singleHouse-white/S__88711190_11_11zon.webp'),
                    img19: require('../../src/assets/singleHouse-white/S__88711192_12_11zon.webp'),
                    img20: require('../../src/assets/singleHouse-white/S__88711193_13_11zon.webp'),
                    cateProject: this.$t('singleProject.cateProject1'),
                    description1: this.$t('singleProject.description1Project1'),
                    description2: this.$t('singleProject.description2Project1'),
                    description3: this.$t('singleProject.description3Project1'),
                    price: this.$t('singleProject.priceProject1'),
                    bedRoom: this.$t('singleProject.bedRoomProject1'),
                    toilet: this.$t('singleProject.toiletProject1'),
                    location: this.$t('singleProject.locationProject1'),
                    area: this.$t('singleProject.areaProject1'),
                    livingRoom: this.$t('singleProject.livingRoomProject1')
                },
                {
                    projectId: 2,
                    img: require('../../src/assets/singleHouse-sankampang.webp'),
                    cateProject: this.$t('singleProject.cateProject2'),
                    description1: this.$t('singleProject.description1Project2'),
                    description2: this.$t('singleProject.description2Project2'),
                    description3: this.$t('singleProject.description3Project2'),
                    price: this.$t('singleProject.priceProject2'),
                    bedRoom: this.$t('singleProject.bedRoomProject2'),
                    toilet: this.$t('singleProject.toiletProject2'),
                    location: this.$t('singleProject.locationProject2'),
                    area: this.$t('singleProject.areaProject2'),
                    livingRoom: this.$t('singleProject.livingRoomProject2')
                },
                {
                    projectId: 3,
                    img: require('../../src/assets/havenna.webp'),
                    cateProject: this.$t('singleProject.cateProject3'),
                    description1: this.$t('singleProject.description1Project3'),
                    description2: this.$t('singleProject.description2Project3'),
                    description3: this.$t('singleProject.description3Project3'),
                    price: this.$t('singleProject.priceProject3'),
                    bedRoom: this.$t('singleProject.bedRoomProject3'),
                    toilet: this.$t('singleProject.toiletProject3'),
                    location: this.$t('singleProject.locationProject3'),
                    area: this.$t('singleProject.areaProject3'),
                    livingRoom: this.$t('singleProject.livingRoomProject3')
                },
                {
                    projectId: 4,
                    img: require('../../src/assets/SD-Condo-1.webp'),
                    img2: require('../../src/assets/SD-Condo/SD-Condo-2.webp'),
                    img3: require('../../src/assets/SD-Condo/SD-Condo-3.webp'),
                    img4: require('../../src/assets/SD-Condo/SD-Condo-5.webp'),
                    img5: require('../../src/assets/SD-Condo/SD-Condo-4.webp'),
                    img6: require('../../src/assets/SD-Condo/SD-Condo-1.webp'),
                    img7: require('../../src/assets/SD-Condo/SD-Condo-6.webp'),
                    cateProject: this.$t('singleProject.cateProject4'),
                    description1: this.$t('singleProject.description1Project4'),
                    description2: this.$t('singleProject.description2Project4'),
                    description3: this.$t('singleProject.description3Project4'),
                    price: this.$t('singleProject.priceProject4'),
                    bedRoom: this.$t('singleProject.bedRoomProject4'),
                    toilet: this.$t('singleProject.toiletProject4'),
                    location: this.$t('singleProject.locationProject4'),
                    area: this.$t('singleProject.areaProject4'),
                    livingRoom: this.$t('singleProject.livingRoomProject4')
                },
            ]
        }
    }
}
</script>